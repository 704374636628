<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Date </label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),

                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Branch"
                rules="required"
              >
                <v-select
                  v-model="branch"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="branchOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Narration</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Narration"
                rules="required"
              >
                <b-form-input
                  v-model="narration"
                  placeholder="Enter Narration"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  v-model="type"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['Journal', 'Credit Note', 'Debit Note']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Voucher Info" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 28.9cm">
                <thead class="text">
                  <tr>
                    <th style="width: 1cm">ID</th>
                    <th style="width: 5cm">
                      Dr./Cr.<label style="color: red !important">*</label>
                    </th>
                    <th style="width: 5cm">
                      ledger<label style="color: red !important">*</label>
                    </th>
                    <th style="width: 6cm">Amount</th>
                    <th style="width: 1cm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in voucherinfo" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="This"
                      >
                        <v-select
                          v-model="item.drcr"
                          placeholder="None"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Debit', 'Credit']"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="ledger"
                      >
                        <v-select
                          v-model="item.ledger"
                          placeholder="None"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="ledgerOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input v-model="item.amount" placeholder="None" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                        <!-- <span>Delete</span> -->
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              :disabled="submitDisable"
              @click.prevent="submitForm"
            >
              Submit
            </b-button>
            <b-button class="ml-1" variant="primary" @click="onClickBack"
              >Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
  },
  data() {
    return {
      date: moment(new Date()).format("DD/MM/yyyy"),
      branch: "",
      narration: "",
      voucherinfo: [
        {
          drcr: "",
          ledger: "",
          amount: "",
        },
      ],
      type: "",

      text: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      branchOption: [],
      ledgerOption: [],
      ifEdit: false,
      submitDisable: false,
      id: "",
      test: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Voucher") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/voucher");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/voucher");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getvoucherById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.voucherinfo = [];
      item.map((item) => {
        this.date = item.date;
        this.branch = this.getBranch(item.branch);
        this.narration = item.narration;
        this.test = item.VoucherItem;
        this.type = item.type;
        this.test.map((item) => {
          this.voucherinfo.push({
            ledger: item.Ledgername,
            amount: item.amount,
            drcr: item.drcr,
            Ledgername: item.ledger,
          });
        });
      });
    },

    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success == true) {
          this.handleInfo();
          data = {
            date: this.date,
            branch: this.branch.id,
            narration: this.narration,
            type: this.type,
            data: this.voucherinfo,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.voucherinfo.length !== 0) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/voucher/${this.$route.params.id}`
              : `${baseApi}/voucher`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = true;

              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/accounting/voucher");
            })
            .catch((error) => {
              this.submitDisable = true;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/accounting/voucher");
    },
    handleInfo() {
      if (this.voucherinfo.length == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            variant: "danger",
            text: "please Enter Voucher Information ",
          },
        });
      } else {
        this.voucherinfo.map((item) => {
          if (!item.Ledgername) {
            item.ledger = item.ledger.id;
          } else if (item.ledger.id) {
            const x = item.ledger;
            item.ledger = x.id;
            item.Ledgername = x.name;
          } else {
            const y = item.ledger;
            item.ledger = item.Ledgername;
            item.Ledgername = y;
          }
        });
      }
    },
    async init() {
      this.getBranch();
      this.getLedger();
    },
    async getBranch(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/branch`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.branchOption = response.data.data.data;

          this.branchOption.map((item) => {
            if (this.id == item.id) {
              this.branch = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLedger(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/ledger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.ledgerOption = response.data.data.data;
          this.ledgerOption.map((item) => {
            if (item.id == parseInt(this.id)) {
              this.ledgername = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    addRow() {
      this.voucherinfo.push({
        dcdr: "",
        ledger: "",
        amount: "",
      });
    },
    removeRow(id) {
      this.voucherinfo.splice(id, 1);
    },
  },
};
</script>
